import { Button } from 'antd'
import React from 'react'

import { RoleData } from '../../../../requests'
import { DatacIcon } from '../../../common'

interface GetColumnsOptions {
  columnNames: {
    role: string
    users: string
    actions: string
  }
  onViewRole: (id: string, isEditingDisabled: boolean) => void
}
export const getColumns = ({ columnNames, onViewRole }: GetColumnsOptions) => {
  return [
    {
      title: columnNames.role,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true
    },
    {
      title: columnNames.users,
      dataIndex: 'usersCount',
      key: 'usersCount',
      width: '20%'
    },
    {
      title: columnNames.actions,
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      render: (id: string, role: RoleData) => (
        <div className="settings-roles-table__actions">
          <Button className="datac-button--icon-only" onClick={() => onViewRole(id, true)}>
            <DatacIcon name="eye" />
          </Button>
          {!role.isPredefined && (
            <Button className="datac-button--icon-only" onClick={() => onViewRole(id, false)}>
              <DatacIcon name="edit" />
            </Button>
          )}
        </div>
      )
    }
  ]
}
