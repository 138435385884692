import './SettingsRolesViewAndEdit.less'

import { Button, Form, Input, Switch } from 'antd'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  AclCategory,
  AclFeature,
  PermissionsCategories,
  RoleDetails,
  SinglePermission,
  fetchRoleDetails,
  updateRoleDetails
} from '../../../../requests'
import { UserContext } from '../../../auth'
import { DatacIcon, DatacLoading, DatacMessage, DatacTitle, DatacToggleExpand } from '../../../common'

interface Props {
  roleId: string
  isRoleEditDisabled: boolean
  onGoBack: () => void
}

type PermissionFeatures = Record<AclCategory, Record<AclFeature, SinglePermission[]>>

export const SettingsRolesViewAndEdit: React.FC<Props> = ({ roleId, isRoleEditDisabled, onGoBack }) => {
  const intl = useScopedIntl('')
  const intlRoles = useScopedIntl('settings.roles')
  const intlFeatures = useScopedIntl('settings.roles.feature')
  const intlActions = useScopedIntl('settings.roles.action')
  const [form] = Form.useForm()
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isNameTaken, setIsNameTaken] = useState(false)
  const [role, setRole] = useState<RoleDetails>(null)
  const [permissions, setPermissions] = useState<string[]>(null)
  const [categories, setCategories] = useState<PermissionsCategories>(null)
  const [features, setFeatures] = useState<PermissionFeatures>(null)
  const [openedCategories, setOpenedCategories] = useState<AclCategory[]>([])
  const { user } = useContext(UserContext)

  useEffect(() => {
    fetchRoleDetails(
      { id: roleId },
      {
        onSuccess: role => {
          setRole(role)
          setInitialData(role)
          setIsLoading(false)
        },
        onRequestError: code => {
          onGoBack()
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }, [])

  const onCancel = () => {
    form.resetFields()
    setInitialData(role)
    setIsNameTaken(false)
    setIsEditingOn(false)
  }

  const onSave = () => form.submit()

  const onSubmit = ({ name }: { name: string }) => {
    setIsSaving(true)
    updateRoleDetails(
      { id: roleId, name, permissions },
      {
        onSuccess: role => {
          setRole(role)
          setInitialData(role)
          setIsEditingOn(false)
          setIsSaving(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsSaving(false)
        },
        onNameTaken: () => {
          setIsNameTaken(true)
          setIsSaving(false)
        }
      }
    )
  }

  const setInitialData = (role: RoleDetails) => {
    setCategories(role.categories)

    const categoryFeatures = (features: AclFeature[]) =>
      features.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: role.permissions[curr]
        }),
        {} as Record<AclFeature, SinglePermission[]>
      )

    setFeatures(
      Object.entries(role.categories).reduce(
        (acc, [category, features]) => ({
          ...acc,
          [category]: categoryFeatures(features)
        }),
        {} as PermissionFeatures
      )
    )

    setPermissions(
      Object.values(role.permissions).reduce((acc, curr) => {
        acc.push(...curr.filter(v => v.isOn).map(v => v.id))
        return acc
      }, [] as string[])
    )
  }

  const changePermission = (value: boolean, id: string) => {
    const currentPermissions = permissions
    if (value) {
      currentPermissions.push(id)
    } else {
      const index = currentPermissions.indexOf(id)
      if (index > -1) currentPermissions.splice(index, 1)
    }
    setPermissions([...currentPermissions])
  }

  const actionPermission = (id: string) => (
    <div className="settings-roles-edit__block-body__feature-action-permission">
      {isRoleEditDisabled ? (
        <div className={classNames('permission-indicator', permissions.includes(id) && 'permission-indicator--on')} />
      ) : (
        <Switch
          disabled={!isEditingOn}
          checked={permissions.includes(id)}
          onChange={value => changePermission(value, id)}
        />
      )}
    </div>
  )

  const rolePermissions = (category: AclCategory) =>
    permissions &&
    categories &&
    features &&
    Object.entries(features[category]).map(([featureName, actions]) => (
      <div className="settings-roles-edit__block-body__feature" key={featureName}>
        <div className="settings-roles-edit__block-body__feature-name">
          {intlFeatures(featureName)}
          <div className="settings-roles-edit__block-body__feature-name-description">
            {intlFeatures(`${featureName}.description`)}
          </div>
        </div>
        <div className="settings-roles-edit__block-body__feature-actions">
          {actions.map(action => (
            <div className="settings-roles-edit__block-body__feature-action" key={action.id}>
              <div className="settings-roles-edit__block-body__feature-action-name">{intlActions(action.name)}</div>
              {actionPermission(action.id)}
            </div>
          ))}
        </div>
      </div>
    ))

  const toggleCategory = (category: AclCategory) => {
    const currentOpenedCategories = openedCategories
    if (currentOpenedCategories.includes(category)) {
      const index = currentOpenedCategories.indexOf(category)
      currentOpenedCategories.splice(index, 1)
    } else {
      currentOpenedCategories.push(category)
    }
    setOpenedCategories([...currentOpenedCategories])
  }

  const isCategoryEnabled = (category: AclCategory) => {
    switch (category) {
      case AclCategory.Analysis:
        return user.isDataAnalysisEnabled
      case AclCategory.Econsent:
        return user.isEconsentEnabled
      case AclCategory.Econsult:
        return user.isEconsultEnabled
      case AclCategory.Epro:
        return user.isEproEnabled
      case AclCategory.SubjectRepository:
        return user.isSubjectRepositoryEnabled
      case AclCategory.Calendar:
        return user.isCalendarEnabled
      case AclCategory.Payments:
        return user.isPaymentsEnabled
      case AclCategory.SideBySide:
        return user.isSideBySideEnabled
      default:
        return true
    }
  }

  return (
    <DatacLoading isLoading={isLoading}>
      <div className="settings-roles-content__header">
        <div onClick={() => onGoBack()} className="settings-roles-edit__go-back">
          <DatacIcon className="settings-roles-edit__go-back-icon" name="arrowLeft" type="blue" size="small" />
          {intlRoles('back')}
        </div>
        <DatacTitle type="h2" className="settings-roles-edit__header">
          {intlRoles('column.role')}:
        </DatacTitle>
        <DatacTitle type="h2" className="settings-roles-edit__header">
          {role?.name}
        </DatacTitle>
        <div className="settings-roles-edit__space" />
        <div className="settings-roles-content__header-controls">
          {!isRoleEditDisabled && !isEditingOn && (
            <Button type="primary" size="large" onClick={() => setIsEditingOn(true)}>
              {intlRoles('edit')}
            </Button>
          )}
          {isEditingOn && (
            <>
              <Button type="default" size="large" onClick={onCancel}>
                {intlRoles('cancel')}
              </Button>
              <Button type="primary" size="large" onClick={onSave} loading={isSaving}>
                {intlRoles('save')}
              </Button>
            </>
          )}
        </div>
      </div>
      {role && permissions && (
        <Form form={form} onFinish={data => onSubmit({ name: data.name })} name="settings-users-edit-form">
          <div className="settings-roles-edit__block">
            <div className="settings-roles-edit__block-header">{intlRoles('settings')}</div>
            <div className="settings-roles-edit__block-body">
              <div className="settings-roles-edit__settings-row">
                <span className="settings-roles-edit__settings-row-label">{intlRoles('name.label')}</span>
                {isRoleEditDisabled ? (
                  <span>{role.name}</span>
                ) : (
                  <Form.Item
                    name="name"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: intlRoles('name.validation.required')
                      }
                    ]}
                    validateStatus={isNameTaken ? 'error' : undefined}
                    help={isNameTaken ? intlRoles('name.validation.taken') : undefined}
                    initialValue={role.name}
                  >
                    <Input
                      size="large"
                      placeholder={intlRoles('name.placeholder')}
                      disabled={!isEditingOn}
                      onChange={() => setIsNameTaken(false)}
                    />
                  </Form.Item>
                )}
              </div>
            </div>
          </div>
          <DatacTitle className="settings-roles-edit__permissions-title">{intlRoles('permissions')}</DatacTitle>
          {Object.values(AclCategory).map(
            category =>
              isCategoryEnabled(category) && (
                <div className="settings-roles-edit__block" key={category}>
                  <div className="settings-roles-edit__block-header">
                    <DatacTitle size="small">{intlRoles(`category.${category.toLowerCase()}`)}</DatacTitle>
                    <DatacToggleExpand
                      isCollapsed={!openedCategories.includes(category)}
                      onToggle={() => toggleCategory(category)}
                    />
                  </div>
                  <div className="settings-roles-edit__block-body">
                    {openedCategories.includes(category) && (
                      <>
                        <div
                          className={classNames(
                            'settings-roles-edit__block-body__feature',
                            'settings-roles-edit__block-body__header'
                          )}
                        >
                          <div className="settings-roles-edit__block-body__feature-name">{intlRoles('feature')}</div>
                          <div className="settings-roles-edit__block-body__feature-actions">
                            <div className="settings-roles-edit__block-body__feature-action">
                              <div className="settings-roles-edit__block-body__feature-action-name">
                                {intlRoles('action')}
                              </div>
                              <div className="settings-roles-edit__block-body__feature-action-permission">
                                {intlRoles('permission')}
                              </div>
                            </div>
                          </div>
                        </div>
                        {rolePermissions(category)}
                      </>
                    )}
                  </div>
                </div>
              )
          )}
        </Form>
      )}
      <div className="settings-roles-content__header">
        <div onClick={() => onGoBack()} className="settings-roles-edit__go-back">
          <DatacIcon className="settings-roles-edit__go-back-icon" name="arrowLeft" type="blue" size="small" />{' '}
          {intlRoles('back')}
        </div>
        <div className="settings-roles-edit__space" />
        <div className="settings-roles-content__header-controls">
          {!isRoleEditDisabled && !isEditingOn && (
            <Button type="primary" size="large" onClick={() => setIsEditingOn(true)}>
              {intlRoles('edit')}
            </Button>
          )}
          {isEditingOn && (
            <>
              <Button type="default" size="large" onClick={onCancel}>
                {intlRoles('cancel')}
              </Button>
              <Button type="primary" size="large" onClick={onSave} loading={isSaving}>
                {intlRoles('save')}
              </Button>
            </>
          )}
        </div>
      </div>
    </DatacLoading>
  )
}
