import './SettingsRolesAddModal.less'

import { Form, Input } from 'antd'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { addRole } from '../../../../requests'
import { maxTitleLengthLong, validateRequired } from '../../../../validation'
import { DatacFormItem, DatacMessage, DatacModal } from '../../../common'

interface Props {
  isOpened: boolean
  onClose: () => void
  onRoleAdded: () => void
}

export const SettingsRolesAddModal: React.FC<Props> = ({ isOpened, onClose, onRoleAdded }) => {
  const intl = useScopedIntl('')
  const intlRoles = useScopedIntl('settings.roles')
  const [formInstance] = Form.useForm()
  const [isAddingRole, setIsAddingRole] = useState(false)
  const [isNameTaken, setIsNameTaken] = useState(false)

  const onSubmit = ({ name }: { name: string }) => {
    setIsAddingRole(true)
    addRole(
      { name },
      {
        onSuccess: () => {
          setIsAddingRole(false)
          onClose()
          onRoleAdded()
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsAddingRole(false)
        },
        onNameTaken: () => {
          setIsNameTaken(true)
          setIsAddingRole(false)
        }
      }
    )
  }

  const onNameChange = () => {
    if (isNameTaken) setIsNameTaken(false)
  }

  return (
    <DatacModal
      isOpened={isOpened}
      onClose={onClose}
      afterClose={() => formInstance.resetFields()}
      title={intlRoles('add_modal.title')}
      onSubmit={() => formInstance.submit()}
      loading={isAddingRole}
      destroyOnClose
    >
      <Form
        form={formInstance}
        name="settings-roles-add-form"
        className="settings-roles-add-modal__form"
        onFinish={onSubmit}
      >
        <DatacFormItem
          name="name"
          label={intlRoles('name.label')}
          validate={validateRequired(intlRoles('name.validation.required'))}
          error={isNameTaken ? intlRoles('name.validation.taken') : undefined}
        >
          <Input
            className="settings-roles-add-modal__input"
            placeholder={intlRoles('name.placeholder')}
            maxLength={maxTitleLengthLong}
            onChange={onNameChange}
          />
        </DatacFormItem>
      </Form>
    </DatacModal>
  )
}
